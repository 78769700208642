import { useCallback, useMemo, useState } from 'react';

import Cookies from 'universal-cookie';
import { Cookie, CookieSetOptions } from 'universal-cookie/cjs/types';
import { useDidMount } from './hooks/useDidMount';
import { format, parse } from 'date-fns';
import { ru, enUS } from 'date-fns/locale';

export const useLocalStorage = (key: string, initialValue: any) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: any) => {
        try {
            // Allow value to be a function, so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // TODO
        }
    };
    return [storedValue, setValue];
};

export const useCookie = (key: string, initialValue: any) => {
    const cookies = useMemo(() => new Cookies(null, { path: '/' }), []);

    const [value, setValue] = useState(() => {
        const cookie = cookies.get(key);
        if (cookie) return cookie;
        cookies.set(key, initialValue);
        return initialValue;
    });

    const updateCookie = useCallback(
        (newValue: Cookie, options?: CookieSetOptions) => {
            cookies.set(key, newValue, options);
            setValue(newValue);
        },
        [cookies, key]
    );

    const deleteCookie = useCallback(() => {
        cookies.remove(key);
        setValue(null);
    }, [cookies, key]);

    return [value, updateCookie, deleteCookie];
};

const sortingType = (el: any) => {
    if (+el) return +el;
    const maybeDate = new Date(el);
    if (!isNaN(maybeDate.getTime())) return maybeDate;
    return el;
};

export const sort = (elA: any, elB: any) => {
    if (sortingType(elA) > sortingType(elB)) return 1;
    if (sortingType(elA) < sortingType(elB)) return -1;
    return 0;
};

export const randomId = (length = 6) =>
    Math.random()
        .toString(36)
        .substring(2, length + 2);

export const formatKilo = (n: number): string => numberSeparate(n, '\u00A0');

export const numberSeparate = (x: number, s: string): string => {
    if (!x) return '';
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, s);
};

export const rank = (r: number): string => {
    if (r < 2100) {
        return Math.ceil((2100 - r) / 75) + 'k';
    }
    return Math.floor((r - 2000) / 100) + 'd';
};

export const dateMonthYear = (date: string): string => {
    const d = new Date(date);
    return `${padZero(d.getMonth() + 1, 2)}/${d.getFullYear()}`;
};

export const padZero = (num: number, size: number): string => {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
};

export const formatNumberWithSign = (number: number) => {
    if (number > 0) return `+${number}`;
    if (number === 0) return `=`;
    return `-${number}`;
};

export const createOptionsForSelectYears = (start: number, end?: number) => {
    if (end === undefined) end = new Date().getFullYear();

    const years = [];

    for (let year = end; year >= start; year--) {
        years.push({ value: year.toString(), label: year.toString() });
    }

    return years;
};

export const yearToMinAndMaxDate = (year: string) => {
    if (!year) return null;
    return { minStartDate: `${year}-01-01`, maxStartDate: `${year}-12-31` };
};

export const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // месяцы начинаются с 0
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export const useIsMobile = (trigger: number = 768) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= trigger);

    useDidMount(() => {
        const handle = () => {
            setIsMobile(window.innerWidth <= trigger);
        };
        window.addEventListener('resize', handle);

        return () => {
            window.removeEventListener('resize', handle);
        };
    });

    return isMobile;
};

export const useScreenOrientation = () => {
    const getOrientation = () => (screen.orientation.type.startsWith('landscape') ? 'landscape' : 'portrait');
    const [orientation, setOrientation] = useState<'landscape' | 'portrait'>(getOrientation());

    useDidMount(() => {
        const handleResize = () => {
            setOrientation(getOrientation());
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
    return orientation;
};

export const getNounForm = (number: number, one: string, few: string, many: string) => {
    number = Math.abs(number) % 100;
    const n1 = number % 10;

    if (number > 10 && number < 20) {
        return many;
    }
    if (n1 > 1 && n1 < 5) {
        return few;
    }
    if (n1 === 1) {
        return one;
    }
    return many;
};

export const formatYearMonth = (yearMonth: string, language: string): string => {
    const date = parse(yearMonth, 'yyyy-MM', new Date());
    let options = {};
    if (language === 'ru') options = { locale: ru };
    else options = { locale: enUS };
    return format(date, 'LLLL yyyy', options);
};

export const copyToClipbord = (text: string, setIsOpenTooltip: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text).then(() => {
            setIsOpenTooltip(true);
            setTimeout(() => setIsOpenTooltip(false), 1000);
        });
    } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setIsOpenTooltip(true);
        setTimeout(() => setIsOpenTooltip(false), 1000);
    }
};

export const formatAmount = (amount: number, lang: string = 'en') => {
    const suffixes = {
        ru: ['', ' тыс', ' млн', ' млрд'],
        en: ['', 'k', 'M', 'B'],
    };

    let i = 0;

    type SupportedLanguage = keyof typeof suffixes;

    function isSupportedLanguage(lang: string): lang is SupportedLanguage {
        return lang in suffixes;
    }

    const currentLang: SupportedLanguage = isSupportedLanguage(lang) ? lang : 'en';

    // Пока число больше или равно 1000, делим его и увеличиваем индекс суффикса
    while (amount >= 1000 && i < suffixes[currentLang].length - 1) {
        amount /= 1000;
        i++;
    }

    // Округляем до 3 значащих цифр, чтобы всегда оставались 3 символа (например, 1.12K)
    const roundedNum = amount.toFixed(amount >= 100 ? 0 : amount >= 10 ? 1 : 2);

    // Возвращаем число с соответствующим суффиксом
    return { num: parseInt(roundedNum), suffix: suffixes[currentLang][i] };
};

export const playerCount = (status: string, players?: number, registered?: number): number =>
    status === 'ended' || !!players ? players! : registered!;

export const bytesToSize = (bytes: number, lang = 'en', precision = 2) => {
    const units = {
        ru: ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ'],
        en: ['B', 'KB', 'MB', 'GB', 'TB'],
    };

    type SupportedLanguage = keyof typeof units;

    function isSupportedLanguage(lang: string): lang is SupportedLanguage {
        return lang in units;
    }

    const currentLang: SupportedLanguage = isSupportedLanguage(lang) ? lang : 'en';
    if (bytes === 0) return '0 Байт';
    const factor = 1024;
    const index = Math.floor(Math.log(bytes) / Math.log(factor));
    return (bytes / Math.pow(factor, index)).toFixed(precision) + ' ' + units[currentLang][index];
};
